
.toggle-button {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   height: 24px;
   width: 30px;
   background: transparent;
   border:none;
   cursor: pointer;
   padding: 0;
   box-sizing: border-box;
}

.toggle-button:focus {
   outline: none;
   border:transparent;
}

.toggle-button__line {
   width: 30px;
   height: 2px;
   background-color: rgb(187, 176, 176);
   border:transparent;
}

@media(min-width : 769px) {
   .toggle-button {
      display: none;
   }
}