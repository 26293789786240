.page-title {
	font-family:Roboto;
	background-color:transparent;
	color:#3d3d3d;
	font-style: normal;
	font-weight: bold;
	padding-top: 20px;
	font-size: 15px;
	letter-spacing: 0.83px;	
}

.page-title-text {
	font-family:Roboto;
	background-color:transparent;
	color:#3d3d3d;
	font-style: normal;
	font-weight: normal;
	padding-top: 10px;
	font-size: 14px;
	letter-spacing: 0.74px;
}

.SearcBox {
	font-family: Roboto;
	font-size: 12px;
	width: 394px;
	height: 32px;
	margin: 0 8px 0 0;
	padding: 9px 18px 9px 10px;
	border-radius: 5px;
	 content: "none" !important;
	border: solid 1px #c4cfd6 !important;
	background-color: #ffffff;
	vertical-align: center;
	
  }

  .Refresh {
	font-family: Roboto;
	font-size: 12px;
	font-weight: 500;  
	font-stretch: normal;
	font-style: normal;
	width: 109px;
	height: 32px;
	color: #1ea1f1;
	margin: 0 0 0 8px;
	padding: 9px 14px 9px 17px;
	border-radius: 5px;
	border: solid 1.1px #1ea1f1;
	background-color: #ffffff;
  }

  .Cancel_Edit {
	font-family: Roboto;
	font-size: 12px;
	font-weight: 500;  
	font-stretch: normal;
	font-style: normal;
	width: 109px;
	height: 32px;
	color: #1ea1f1;
	margin: 0 0 0 8px;
	border-radius: 5px;
	border: solid 1.1px #1ea1f1;
	background-color: #ffffff;
  }

  .Cancel_PopUp {
	font-family: Roboto;
	font-size: 12px;
	font-weight: 500;  
	font-stretch: normal;
	font-style: normal;
	width: 109px;
	height: 32px;
	color: #1ea1f1;
	margin: 0 0 0 8px;
	border-radius: 5px;
	background-color: #ffffff;
  }

  .Add_New{
	width: 150px;
	height: 36px;
	margin: 16px 0 12px 320px;
	padding: 11px 11px;
	border-radius: 5px;
	background-color: #1ea1f1;

  }

  .Detail_Field {
	 font-family: Roboto;
	 font-size: 12px;
	 font-weight: normal;
	 font-stretch: normal;
	 font-style: normal;
	 line-height: normal;
	 letter-spacing: normal;
	 color: #151d25;
}
.Table_Data {
	width: 1174px;
	object-fit: contain;
	box-shadow: 0 3px 14px 4px rgba(0, 0, 0, 0.2);
  
  }

  .Save {
	font-family: Roboto;
	font-size: 12px;
	font-weight: 500;  
	font-stretch: normal;
	font-style: normal;
	width: 109px;
	height: 32px;
	color: #ffffff;
	margin: 0 0 0 8px;
	padding: 9px 14px 9px 17px;
	border-radius: 5px;
	border: solid 1.1px #1ea1f1;
	background-color: #1ea1f1;
  }
  .Add_New{
	width: 150px;
	height: 36px;
	margin: 16px 0 12px 320px;
	padding: 11px 11px;
	border-radius: 5px;
	background-color: #1ea1f1;

  }

  .Sort_By{
	width: 227px;
	height: 32px;
	margin: 4px 4px 0 0;
	padding: 9px 166px 9px 11px;
	border-radius: 4px;
	border: solid 1px #c4cfd6;
	background-color: #ffffff;  
  }

.transparent_btn{
	background: transparent;
	color:#1ea3f7ba;
	font-size: 85%;
	font-weight: bold;
    border-style: solid;	
}
.modal-header {
	background:transparent;
 }
 .modal{
	border-radius: 7px 7px 7px 7px; 
 }

 .MuiInputBase-input {
font-size: 12px !important; 
}

 .MuiInput-underline:before {
    border-bottom: none !important; 
}

.MuiInput-underline:after {
    border-bottom: none !important;
    
}

.react-confirm-alert-body {
	width: 500px;
	font-family: Roboto;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	color: #3d3d3d;
  
  }

  h1, .h1 {
    font-size: 24px;
}


