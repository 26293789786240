
.backdrop {
   position: fixed;
   height: 100%;
   width: 100%;
   top: 0;
   left: 0;
   background: rgba(0,0,0,0.3);
   z-index: 100;
}

@media(min-width : 769px) {
   .backdrop {
      display: none;
   }
}