.dropdown {
  position: absolute;
  top: 100%;
  right:5px;
  width: 200px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}
.btn .btn-primary{
font-family: 'Roboto';
padding: 0;
margin: 0;
font-size: .2rem;
}

.logout_btn{
height: 30px;
cursor: pointer;
}

.Rectangle {
  width: 155px;
  height: 50px;
  margin: 10px 0 10px 15px;
  padding: 10px 0px 10px 10px;
  border-radius: 4px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.dropdownitem{
display:flex;
background-color:#94cdf0ba ;

}
.logout a{
 color:black;
 font-size: .2rem;

}

 a:hover {
  cursor: pointer;
  background-color:#b2daf3ba 
}

.text-bold{
  font-weight: bolder;
}

