
.multiselect-container{
	min-height:100px;
	height:80px;
	
	
}
.my-selector{
	overflow: hidden;

}
