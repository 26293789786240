html {
  height:  100%;
  font-family: 'Roboto';
}
body {
  margin: 0;
  font-family: 'Roboto';
  zoom: 100%;
  background-color:white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100%;
  width: 100%;
  position: fixed;
}
