.recon_btn{
	width: 175px;
	height: 100px;
	margin: 16px 0 12px 320px;  
	padding: 10px 20px;
	border-radius: 10px;
	background-color: #f2f9fe;
	font-family: Roboto;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #030303;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.recon_btn_click{
	width: 175px;
	height: 100px;
	margin: 16px 0 12px 320px;  
	padding: 10px 20px;
	border-radius: 10px;
	background-color: #1ea1f1;
	font-family: Roboto;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #ffffff;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.mail_btn_click{
	width: 175px;
	height: 50px;
	margin: 16px 0 12px;  
	padding: 10px 20px;
	border-radius: 10px;
	background-color: #1ea1f1;
	font-family: Roboto;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #ffffff;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.recon_less_btn{
	width: 212px;
	height: 100px;
	margin: 16px 0 12px 320px;  
	padding: 10px 20px;
	border-radius: 10px;
	background-color:#f2f9fe;
	font-family: Roboto;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #030303;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.recon_less_btn_click{
	width: 212px;
	height: 100px;
	margin: 16px 0 12px 320px;  
	padding: 10px 20px;
	border-radius: 10px;
	background-color: #1ea1f1;
	font-family: Roboto;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #ffffff;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.cloud_icon{
	font-size: 30px;
  }
  .cloud_icon_small{
	font-size: 20px;
  }
  .filter_icon{
	font-size: 50px;
  }
.upload_label {
	background-color: #1ea1f1;
	color: white;
	font-family: Roboto;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	padding: 0.5rem;
	border-radius: 0.3rem;
	cursor: pointer;
	margin-top: 1rem;
  }
  .filter_icon{
	height: 30px;
  }
  .no-bg{
	  background-color: transparent;
	  border: none;
  }
 
a:hover {
	text-decoration: none;
	background-color: transparent;
}

  .filter_btn{
	width: 40px;
	height: 80%; 
	border-radius: 5px;
	background-color:  transparent !important;
	font-family: Roboto;
	font-size: 14px;
	vertical-align: top;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #1ea1f1 !important;
}
.confirm_btn_upload{
	width: 150px;
	height: 40px; 
	border-radius: 5px;
	background-color: #1ea1f1  !important;
	font-family: Roboto;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #ffffff !important;
}

.label-tooltip {
	width: 77px;
	 height: 14px;    
	 font-family: Roboto;
	 font-size: 12px;
	 font-weight: 500;
	 font-stretch: normal;
	 font-style: normal;
	 line-height: normal;
	 letter-spacing: normal;
	 color: #151d25;
}
.border_bottom{
	border-bottom: 1px solid black;
}

.download_button{
	display: flex;
	justify-content: flex-end;
  }
.font_12{
    font-size: 12px;
}
.font_14{
    font-size: 14px;
}
.font_16{
    font-size: 16px;
}
.font_18{
    font-size: 18px;
}
.font_24{
    font-size: 24px;
}
.cursorpointer {
	cursor: pointer;
}
.bg_color{

    background-color: #f2f9fe;
}
.h-30px{
	height:30px;
}

.multiselect-container{
	min-height:120px;
	height:120px;
	
    

}
.my-multiselect{
	min-height:200px;
    z-index:2 ;
	background-color: #ffffff;}

