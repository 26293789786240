.table-dark-bordered {
  border: 1px solid rgb(238, 230, 230);
}

.table-dark-bordered th,
.table-dark-bordered td {
  border: 1px solid rgb(238, 230, 230);
}
.tableBg{
   background-color:#f2f9fe;
   
}
 th{
   font-family: Roboto;
   font-size: 12px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   background:#f2f9fe;
   position: sticky;
   top: 0;
}
.itemNameSM{
   font-family: 'Roboto';
   font-size: 12px;
   font-weight: bold;
}

.table-dark-bordered thead th,
.table-dark-bordered thead td {
  border-bottom-width: 2px;
}

.table-wrapper-scroll-y {
  display: block;
  max-height: 400px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table tr:hover {
   background-color: none;
}

table tr.active {
   background-color: #1ea3f7ba;
   color: white;
}

.showbox {
   width: 50px;
}

tbody tr:nth-child(odd) {
   background-color: #ffffff;
   border: 1px solid #f6f9fa;;
 }
 
 tbody tr:nth-child(even) {
   background-color:#d4d7d8;
   border: 1px solid #f6f9fa;
 }

.page-item {
   cursor: pointer;
   background-color:#f2f9fe;
}

.disable {
   color: lightslategray;
   cursor: default !important;
}
.table thead th, .table td {
   vertical-align: middle;
   border: none;
   border-width: none !important;
   border-top-width: none !important; 
   border-bottom: none !; 

}

.table-bordered th, .table-bordered td {
   border-bottom-width: none !important;
   border:none; 
}
.table thead th {
   border: 1px solid #f2f9fe !important;
}


.pagination_button{
background-color: #f6f9fa;
border-radius: 2px;
color: #3d3d3d;
}

.Max-Rows {
     width: 77px;
      height: 14px;    
      margin: 0 3px 4px 242px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #151d25;
}

.Max_Row_Text {
   font-family: Roboto;
   font-size: 12px;
   width: 80px;
   height: 32px;
   margin: 4px 0 0 242px;
   padding: 9px 18px 9px 12px;
   border-radius: 4px;
   border: solid 1px #c4cfd6 !important;
   background-color: #ffffff;
 
 }

.Active {
   font-family: Roboto;
   font-size: 14px;
   font-weight: normal;
   font-style: normal;
   color: #151d25;
 }

.displaying-page {
   width: 98px;
   height: 16px;
   margin: 11px 12px 5px 0;
   font-family: Roboto;
   font-size: 14px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   vertical-align: center;
   color: #4a4a4a;
 
 }