
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App {
  text-align: center;
}

.window-main-screen {
	position:relative;
	min-height: 100%;
	max-height: 100%;
	left: 0;
	top: 0;
	padding: 0;	
}

main {
	margin-top: 10px;
	left: 0;
	top: 0;
	position: relative;
	min-height: 90%;
	max-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tableBg{
	background-color:#f2f9fe;
 }
 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.item  {
  height: 1em;
  width: 1em;
}

button.itemadd {
  width: 100%;
  min-width: 100%;
}

.itemCategory {
  font-family: 'Roboto';
  font-size: 130%;
  font-weight: bold;
}

.dinetable {
	cursor: pointer;
}
.add-customization{
	font-weight: bold;
	font-size: 18px;
}
.customization {
	border-radius: 4px;
  	background-color: #f1f3f6;
    }

.checkinerror {
	color: rgb(197, 8, 8);
	font-size: 120%;
  	font-weight: bold;
	
  }
.modalTitle {
	font-family: 'Roboto';
	font-size: 90%;
	color: rgb(248, 241, 242); ;
	font-weight: bold;
  }
.itemDescription {
	font-family: 'Roboto';
	font-size: 90%;
	font-weight: bold;
  }
  .subitemDescription {
	font-family: 'Roboto';
	font-size: 90%;
	color: rgb(75, 80, 80); 
	font-weight: bold;
  }
  .customer_img_logo {
    width: 30%; /* or any custom size */
    height: 100%;
        object-fit: contain;

}

.cutomizeItem{
	font-size: x-small ;
}
.mainmenuscreen {
  min-height: 80%;
  max-height: 80%;
  overflow-y: scroll;
}

.reviewscreen {
	min-height: 78%;
	max-height: 78%;
	overflow-y: scroll;
  }
.customClassenable{
	text-decoration: underline;
	cursor: pointer;
	
}
.customClassdisable{
	pointer-events: none;
	cursor: default;
	color: dimgray;
}
.buttonClassdisable{
	pointer-events: none;
	cursor: default;
	color: rgb(12, 11, 11) !important;
}

/*.img_logo {
    width: 18%;  or any custom size 
    height: 100%; 
    object-fit: contain;
}
*/
.customer_toolbar__logo {
	width: 90%;
	height: 100%;
 }
 .dangerItemClass{
	font-size: 90%;
	font-weight: bold;
	background-color: #c0392b;
 }

.myorderscreen{
	min-height: 62%;
	max-height: 62%;
	overflow-y: scroll;
  }
.itemName{
	font-family: 'Roboto';
  	font-size: 90%;
  	font-weight: bold;
	max-width: fit-content;
}

.itemNameSimple{
	font-family: 'Roboto';
  	font-weight: bold;
	  font-size: 12px;
	
}

.reviewitemName{
	font-family: 'Roboto';
  	font-size: 80%;
  	font-weight: bold;
	max-width: fit-content;
}

.smallClass{
	font-family: 'Roboto';
  font-size: x-small;
  font-weight: bold;
  max-height:25%;
}

.orderitemNameHead{
	font-family: 'Roboto';
  font-size: 100%;
  font-weight: bold;
  max-height:25%;
}

.orderitemName{
	font-family: 'Roboto';
  font-size: 90%;
  
}
.tableName{
	font-family: 'Roboto';
  font-size: 90%;
  font-weight: bold;  
}
.menucontent {
  background-color: white;
}
.bottombar {
  background-color : rgb(173, 220, 236)
}


.bottombarbutton {
  width: 20vh;
}


.customer_card {
	height: 100%;
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	background: #1ea3f7ba;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 10px;
	font-size: large;
	/*font-size: 14px;*/
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px;

}


.brand_logo_container {
	position: absolute;
	height: 170px;
	width: 170px;
	top: -75px;
	border-radius: 50%;
	background: #60a3bc;
	padding: 10px;
	text-align: center;
}
.brand_logo {
	height: 150px;
	width: 150px;
	border-radius: 50%;
	border: 2px solid white;
}
.form_container {
	margin-top: 100px;
}


.checkin_btn {
	width: 80%;
	background:#1ea3f7ba !important;
	color:floralwhite !important;
	font-size: 80%;
	font-weight: bold;
	border-color: floralwhite;
	  
}
.confirm_btn{
	width: 150px;
	height: 36px;
	margin: 16px 0 12px 320px;  
	padding: 10px 20px;
	border-radius: 5px;
	background-color: #1ea1f1;
	font-family: Roboto;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #ffffff;
}

.confirm_btn_popup{
	width: 100px;
	height: 40px; 
	border-radius: 5px;
	background-color: #1ea1f1  !important;
	font-family: Roboto;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #ffffff !important;
}


.transparent_btn{
	background: transparent;
	color:#1ea3f7ba;
	font-size: 85%;
	font-weight: bold;
	
}

.closebtn{
	background:rgb(197, 50, 45) !important;
	color:floralwhite !important;
	font-size: 85%;
	font-weight: bold;
}

.table_status{
	background:#22f71eba !important;
	color:floralwhite !important;
	font-size: 85%;
	font-weight: bold;
	
}

.checkin_welcome {
	color:#010202ba !important;
	font-size: 100%;
	font-weight: bold;
}



.checkin{
	height: 100%;
	width: 100%;
	margin-bottom: auto;
	background: #1ea3f7ba;
	justify-content: right;
	flex-direction: column;
	padding-top: 70px;
}

.checkin_ft{
	height: 100%;
	width: 100%;
	margin-bottom: auto;
	justify-content: right;
	flex-direction: column;
	padding-top: 100px;
}
.customer_home{
	background-color:rgb(197, 240, 253);
	height:100%;
	width :100%
}

.btn-order {
	cursor: pointer;
	width: 170px;
	height: 50px;
	margin: 10px 0 10px 20px;  
	padding: 15px 15px 15px 15px;
	border-radius: 50px;
	
	background-color: #1ea1f1;
	font-family: Roboto;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #ffffff;
}

.btn-product {
	cursor: pointer;
	width: 170px;
	height: 50px;
	margin: 10px 0 10px 20px;  
	padding: 15px 15px 15px 15px;
	border-radius: 50rem;
	border-color: #0061f2;
	border:1px solid !important;
	background-color:transparent;
	font-family: Roboto;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.83px;
	text-align: center;
	color: #1ea1f1;
}
.home_toolbar{
	background-color:transparent;
	height:10%;
	width :100%
}
.home_screen{
	height: 40%;
	width: 100%;
	position: relative;
	
}
.home_howitworks{
	height: 45%;
	width: 100%;
	position: relative;
}
.home_screen_text{
	top: 10%;
	position: relative;
}
.home_container{
	position: relative;
}

.home_screen_head{
	font-size:xx-large;
	font-weight: bold;

}
.home_screen_logo{
	justify-content: center;
	width: 50%; /* or any custom size */
    height: 50%; 
	object-position: right;
	
}
.know_more{
	color: skyblue;
	cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.login_btn:focus {
	box-shadow: none !important;
	outline: 0px !important;
}
.login_container {
	padding: 0 2rem;
}
.input-group-text {
	background: #c0392b !important;
	color: white !important;
	border: 0 !important;
	border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
	box-shadow: none !important;
	outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
	background-color: #5153b8 !important;
}
.h-100 {
height: 100vh !important;
}

@media (min-width : 769px) {
	main {
		margin-left: 250px;
	}
}

@media (max-width : 768px) {
	main {
		margin-left: 0px;
	}
}

input[type=text],
textarea {
	border-color: lightblue;
}

main {
	z-index: 20;
}


.inline-block {
   display: inline;
}
.w-100px {
	width: 100px;
}

.form-group.required .control-label:after {
  content: "*";
  color: rgb(17, 0, 255);
}
.max-height-80vh {
	max-height: 80vh;
	
 }

.max-height-50vh {
   max-height: 50vh;
   overflow-y: scroll;
}

.max-height-45vh {
   max-height: 50vh;
   overflow-y: scroll;
}

.max-height-40vh {
   max-height: 50vh;
   overflow-y: scroll;
}
.max-height-30vh {
	max-height: 40vh;
	
 }

.max-height-50vh {
   max-height: 50vh;
   overflow-y: scroll;
}

.max-height-5vh {
	max-height: 5vh;
	
 }

 .max-height-10vh {
	max-height: 10vh;
	
 }
 .max-height-25vh {
	max-height: 35vh;
	
 }

 .tip_percentage{
border-radius: 4px 0px 0px 4px;
border: solid 1px #eceef0;
background-color: #eceef0;
cursor: pointer;

 }
 .tip_percentage1{
	border: solid 1px #eceef0;
	background-color: #eceef0;
	cursor: pointer;
	
}
.tip_percentage1:click{
	background-color: #1ea1f1;
	cursor: pointer;
	
	 }

	 .tip_percentage_last{
		border-radius: 0px 4px 4px 0px;
		border: solid 1px #eceef0;
		background-color: #eceef0;
		cursor: pointer;
		
		 }
 .input-group-text {
    background: #ffff !important;
    color: rgb(32, 30, 30) !important;
	border: solid 1px #c4cfd6 !important;
	
    border-radius: 0.25rem 0 0 0.25rem !important;
}
/*.modal-dialog{
    max-width: 400px;
  }*/

 .react-toggle {
	touch-action: pan-x;
  
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
  
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
  }
  .download_button{
	vertical-align: middle;
	
  }
  .payment_details
{
  color:rgb(62, 148, 228);
  background-color:#f2f9fe; 
}
.total_tax_service{
	background-color:#cdd1d4;
}

.pointer-cursor {
	cursor: pointer;
}
.w-2 {
	width: 2%;
}
.w-3 {
	width: 3%;
}
.w-4 {
	width: 4%;
}
.w-5 {
	width: 5%;
}
.w-5-5 {
	width: 5.5%;
}
.w-6 {
	width: 6%;
}
.w-6-5 {
	width: 6.5%;
}
.w-7 {
	width: 7%;
}
.w-8 {
	width: 8%;
}
.w-8-33 {
	width: 8.33%;
}
.w-9 {
	width: 9%;
}
.w-10 {
	width: 10%;
}
.w-11 {
	width: 11%;
}
.w-12 {
	width: 12%;
}
.w-13 {
	width: 13%;
}
.w-14 {
	width: 14%;
}
.w-15 {
	width: 15%;
}
.w-16 {
	width: 16%;
}
.w-16-66 {
	width: 16.66%;
}
.w-17 {
	width: 17%;
}
.w-18 {
	width: 18%;
}
.w-18-5 {
	width: 18.5%;
}
.w-19 {
	width: 19%;
}
.w-20 {
	width: 20%;
}
.w-21 {
	width: 21%;
}
.w-22 {
	width: 22%;
}
.w-23 {
	width: 23%;
}
.w-24 {
	width: 24%;
}
.w-25 {
	width: 25%;
}
.w-26 {
	width: 26%;
}
.w-27 {
	width: 27%;
}
.w-28 {
	width: 28%;
}
.w-29 {
	width: 29%;
}
.w-30 {
	width: 30%;
}
.w-31 {
	width: 31%;
}
.w-32 {
	width: 32%;
}
.w-33 {
	width: 33%;
}
.w-33-33 {
	width: 33.33%;
}
.w-35 {
	width: 35%;
}
.w-40 {
	width: 40%;
}
.w-41 {
	width: 41%;
}
.w-41-66 {
	width: 41.66%;
}
.w-45 {
	width: 45%;
}
.w-50 {
	width: 50%;
}
.w-58 {
	width: 58%;
}
.w-58-33 {
	width: 58.33%;
}
.w-60 {
	width: 60%;
}
.w-65 {
	width: 65%;
}
.w-66 {
	width: 66%;
}
.w-66-66 {
	width: 66.66%;
}
.w-75 {
	width: 75%;
}
.w-80 {
	width: 80%;
}
.w-83 {
	width: 83%;
}
.w-83-33 {
	width: 83.33%;
}
.w-90 {
	width: 90%;
}
.w-91 {
	width: 90%;
}
.w-91-66 {
	width: 91.66%;
}
.w-100 {
	width: 100%;
}
.mw-25px {
	max-width: 25px;
}

.mw-50px {
	max-width: 50px;
}

.mw-75px {
	max-width: 75px;
}

.mw-100px {
	max-width: 100px;
}
.h-30px {
	height: 30px;
}
.h-20px {
	height: 20px;
}

.mh-100vh {
	max-height: 100vh;
}
.mh-90vh {
	max-height: 90vh;
}
.mh-80vh {
	max-height: 80vh;
}
.mh-70vh {
	max-height: 70vh;
}
.mh-60vh {
	max-height: 60vh;
}
.mh-50vh {
	max-height: 50vh;
}
.mh-45vh {
	max-height: 45vh;
}
.mh-40vh {
	max-height: 40vh;
}
.mh-35vh {
	max-height: 35vh;
}
.mh-30vh {
	max-height: 30vh;
}
.mh-25vh {
	max-height: 25vh;
}
.mh-20vh {
	max-height: 20vh;
}
.mh-15vh {
	max-height: 15vh;
}
.mh-10vh {
	max-height: 10vh;
}
.mh-5vh {
	max-height: 5vh;
}
.xoverflowscroll {
	overflow-x: scroll;
}
.yoverflowscroll {
	overflow-y: scroll;
}
.control-label.col-md-3 {
  justify-content: flex-end;
}

.control-label.col-md-2 {
  justify-content: flex-end;
}

.qrcodeview {
	color: #02685f;
	font-style: italic;
	text-decoration: underline;
	cursor: pointer;
}

	.itemName{
		font-family: 'Roboto';
	  font-size: 80%;
	  font-weight: bold;
		max-width: fit-content;
	}
	.Subtotaltax{
		font-family: 'Roboto';
	  font-size: 80%;
	  font-weight: bold;
	}
	@media(max-width : 768px) {
		.div-sep-btn {
			display: none;
		}
}

