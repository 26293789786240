.large {
    color: white;
}

.modal-header {
    background: #263238;
    color: white;
    height: 50px;

}

.Login_head {
    padding-left: 120px;
    height: 30px;
    font-size: 20px;
}

.close-modal-btn {
    color: red;
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin: 0;
}

.modal-footer {
    background: #263238;
    height: 47px;
    line-height: 0px;
    text-align: right;
    margin: 70px 0px 0px 0px;
}

.modal-body {
    padding: 10px 15px;
    text-align: center;
}

.user_card {
    height: 400px;
    width: 350px;
    margin-top: auto;
    margin-bottom: auto;
    background: transparent;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px;*/

}


.login_btn {

    width: 60%;
    background: #423d3c !important;
    color: white !important;

}

.btn-cancel,
.btn-continue {
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;

}

.header_logo {

    height: 100px;
}

.forgetpassword:hover {
    color: blue;
}

.btn-cancel {
    background-color: #b71c1c;
    float: left;

}

.btn-continue {
    background-color: #1b5e20;
    float: left;
}

.fn_12 {
    font-size: 12px;
}