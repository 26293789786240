.home_welcome{
	color:rgb(158, 160, 165) !important;
	font-size: small;
	font-weight: bold;
}

.home_logo{
	margin-left: 0rem;
	width: 100%;
	height: 100%;
}
.no-background-color,
.no-background-color:hover {
   background-color: transparent;
   
}
.side-drawer {
   width: 210px;
   height: 822px;
  padding: 0 0 32px;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.08);
   position: fixed;
   top : 0;
   left : 0;
   background-color: #ddeffd;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.side-drawer.open {
   transform: translateX(0);
}

.side-drawer ul {
   height: 100%;
   list-style: none;
   display: flex;
   flex-direction: column;
   justify-content: center;
   font-size: 1.0rem;
   font-weight: bold;
}

.side-drawer li {
   margin: 0.2rem 0;
   font-size: 1.0rem;
   font-weight: bold;
}

.side-drawer a {
   color: #1e1f20ba;
   text-decoration: none;
   font-size: 1.0rem;
   
}

.side-drawer a:hover,
.side-drawer a:active {
   color: #1ea3f7ba;
   background-color: transparent;
}

.main-menu-active,
.main-menu-active a {
   color:#1ea3f7ba !important;
   background-color:transparent;

}

.child-menu-active
 {
   color: #1ea3f7ba!important;
   background-color:#f2f9fe;
   border-radius: 7px 7px 7px 7px;
}

.child-menu-active a
 {
   color: #1ea3f7ba!important;
   background-color:transparent;
   border-radius: 7px 7px 7px 7px;
}

.navbarmenuscreen {
   min-height: 40vh;
   max-height: 40vh;
   margin: 20px 20px 20px 20px;
   
 }


@media(min-width : 769px) {
   .side-drawer {
      transform: translateX(0);
      transition: 0.3s ease-out;
      width: 245px;
      z-index: 50;
   }
}

@media(max-width : 768px) {
   .side-drawer {
      transform: translateX(-110%);
      width: 70%;
      max-width: 400px;
      z-index: 200;
   }
}

   
