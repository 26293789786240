/*.toolbar {
   top: 0;
   left: 0;
   position: fixed;
   width: 100%;
   background-color:#1ea3f7ba ;
   height: 60px;
}*/
.toolbar {
   width: 100%;
   height: 150px;
   margin: 0 0 10px;
   padding: 20px 12px 30px 32px;
   background-color: #ddeffd;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
   }
.img_logo {
	width: 50px;
	height: 45px;
  }

 a {
   color : white;
   text-decoration: none;
   font-size: 1.5rem;
} 
 
.toobar__navigation {
   align-items: left;
   height: 100%;
   
}
/*.toolbar__logo {
   padding: 0rem;
   margin-left: 0rem;
   height: 100%;
   width: 100%;
}*/
.toolbar__logo {
width: 72px;
height: 72px;
margin: 0 12px 21px 0;
padding: 16px 12px 17px 13px;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
background-color: #ffffff;
border-radius: 35px 35px 35px 35px;
}
.spacer {
   flex : 1
}
.toolbar_navigation-items ul {
   list-style: none;
   display: flex;
   margin: 0;
   padding: 0;
   font-size: .2rem;
   font-weight: bold;
}

.toolbar_navigation-items li {
   padding: 0 1rem;
   font-size: .2rem;
   font-weight: bold;
}

.toolbar_navigation-items a {
   color: white;
   text-decoration: none;
}

.toolbar_navigation-items, 
.toolbar_navigation-items a:active {
   /*color: thistle;*/
   color: #ffffff;
   
}


@media(min-width : 769px) {
   .toolbar {
         z-index: 200;
   }
}